<template>
    <div class="subnav">
        <nav class="subnav__nav">
            <slot name="subnav-list" />
        </nav>
    </div>
</template>

<script>
export default {
  name: 'subnav',
};
</script>

<style lang="scss" scoped>
</style>
