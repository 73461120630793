import Vue from 'vue';
import VueMq from 'vue-mq';
import App from './App.vue';
import router from './router';
import store from './store';
import titleMixin from './mixins/titleMixin';

Vue.mixin(titleMixin);

const VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);

Vue.use(VueMq, {
  breakpoints: {
    xxs: 415,
    xs: 768,
    sm: 1024,
    md: 1280,
    lg: 1366,
    xl: 1920,
    xxl: Infinity,
  },
});

Vue.config.productionTip = false;

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
