<!-- eslint-disable max-len -->
<template>
    <div class="content">
        <page-template>
            <div slot="page-header">
                <page-header>
                    <div slot="header-buttons">
                        <router-link :to="{name: 'Content Undergrad General Multi'}" class="button button-primary">
                            <span class='icon-left'>
                                <i class='icon-left-arrow'></i>
                            </span>
                            Back
                        </router-link>
                    </div>
                    <div slot="mobile-buttons">
                        <router-link :to="{name: 'Content Undergrad General Multi'}" class="button button-primary">
                            <span class='icon-left'>
                                <i class='icon-left-arrow'></i>
                            </span>
                            Back
                        </router-link>
                    </div>
                </page-header>
            </div>
            <div slot="content" class="">
                <subnav>
                    <ul class="flex subnav__nav-list" slot="subnav-list">
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Home'}">Home</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Overview'}">Overview</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Audience'}">Audience</router-link><span class="icon-chevron-right margin-x-3 flex flex-items-center"></span>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Goal Undergraduate'}">Goal</router-link><span class="icon-chevron-right margin-x-3 flex flex-items-center"></span>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Campaign Topic Undergraduate General Information'}">Campaign Topic</router-link><span class="icon-chevron-right margin-x-3 flex flex-items-center"></span>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Content Undergrad General Multi'}">Content</router-link><span class="icon-chevron-right margin-x-3 flex flex-items-center"></span>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'CTA Undergrad General Multi'}">Call To Action</router-link>
                        </li>
                    </ul>
                </subnav>
                <div class="content__body">
                    <div class="content__section">
                        <div class="content__navigation">
                            <div class="content__navigation-fixed">
                                <h3 class="heading-size-h4 is-bold">CTA Sections</h3>
                                <ol>
                                    <li>
                                        <a href="#" v-scroll-to="'#primary-cta'">Primary CTA</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#recommendations'">CTA Text Recommendations</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#analytics'">Analytics Recommendations</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="content__section-content">
                            <div class="content__header">
                                <div class="content__header-content">
                                    <p class="is-bold">Undergraduate | Informational | Multi-Programs</p>
                                    <h1>5. Call To Action</h1>
                                    <p>Information pages should focus on CTAs like “Apply Now”, “Next Step”, etc to drive prospective or admitted students to the next page.</p>
                                    <p>Example - If it’s “Apply Now” the CTA would connect to the application page rather than opening a form on this page.</p>
                                    <p>If there is a need for a form it would be a quick form for information that would be minimal and short.</p>
                                    <div class="flex flex-justify-start flex-items-start margin-top-3">
                                        <a href="https://app.unbounce.com/3916651/pages/39dfcb05-ce34-4f4c-a06d-169941b7f6bf/overview" class="button button-primary">
                                            Find Your Template
                                            <span class='icon-right'>
                                                <i class='icon-right-arrow'></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="content__section-columns">
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/cta-content-single.jpg" alt="CTA Link" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h2 class="heading-size-h4 is-bold anchor-link" id="primary-cta" tabindex="-1">01. Primary CTA</h2>
                                    <p>A content focused landing page will need to relay information around this program/school that will be enticing enough for a possible Undergrad prospect to interact with the page and use the CTA to determine their next steps in making an appointment, receiving more information, continuing to another page for a program or applying.  </p>
                                    <p>Ensure the message matches the ads; it should be user-focused (avoid “I”, “we”, etc.)</p>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/LeadSingleCTA2.png" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h2 class="heading-size-h4 is-bold anchor-link" id="recommendations" tabindex="-1">02. Call-To-Action Button Text Recommendations:</h2>
                                    <ul>
                                        <li>Request Info</li>
                                        <li>More Information</li>
                                        <li>Get In Touch</li>
                                        <li>Next Step</li>
                                        <li>Download Brochure</li>
                                        <li>Apply Now</li>
                                        <li>Schedule Appointment</li>
                                    </ul>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/Logo_Google_Analytics.svg"  style="padding: 0 1.5rem" alt="Google Analytics" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="analytics" tabindex="-1">03. Analytics Recommendations</h3>
                                    <ul>
                                        <li>Scroll Depth </li>
                                        <li>Form Started/Attempts</li>
                                        <li>Form Submissions</li>
                                        <li>Button Clicks</li>
                                        <li>Outbound Links</li>
                                        <li>Contact Methods (click to call, mailto)</li>
                                        <li>Footer Navigation</li>
                                        <li>Expand and Close Drop-Down Menus</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </page-template>
    </div>
</template>

<script>
import PageTemplate from '../components/base/page-template.vue';
import Subnav from '../components/base/subnav.vue';
import PageHeader from '../components/base/page-header.vue';

export default {
  name: 'Content',
  title: 'Decision Tree - Content',
  components: {
    PageTemplate,
    PageHeader,
    Subnav,
  },
};
</script>

<style lang="scss" scoped>
</style>
