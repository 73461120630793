<template>
    <div class="page-template">
        <slot name="page-header" />
        <main class="page-template__content">
            <slot name="subnav" />
            <div class="page-template__content-container" id="maincontent">
                <slot name="content" />
            </div>
        </main>
    </div>
</template>

<script>

export default {
  name: 'page-template',
  components: {
  },
  props: {
    hasSubnav: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
    .page-template {
        height: 100%;
        width: 100%;
        position: relative;
        background-color: $white;
        @include breakpoint(md) {
            overflow: hidden;
        }
        &__content {
            height: 100%;
            width: 100%;
            &-container {
                height: 100%;
                width: 100%;
            }
        }
    }
</style>
