<template>
    <div class="page-header">
        <div class="page-header__brand">
          <router-link :to="{name: 'Home'}" class="page-header__home-link">
            <su-logo />
          </router-link>
        </div>
        <div class="page-header__button-container"
             v-if="$mq === 'sm' || $mq === 'md' || $mq === 'lg' || $mq === 'xl' || $mq === 'xxl'">
          <slot name="header-buttons" />
        </div>
        <div class="page-header__button-container-mobile"
             v-if="$mq === 'xxs' || $mq === 'xs'">
          <slot name="mobile-buttons" />
        </div>
    </div>
</template>

<script>
import SuLogo from '../images/su-logo.vue';

export default {
  name: 'page-header',
  components: {
    SuLogo,
  },
};
</script>

<style lang="scss" scoped>
    .page-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: $white;
      position: fixed;
      top: 0;
      z-index: 999;
      @include breakpoint(md) {
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 2px solid $su-orange-primary;
        height: 80px;
      }
      &__brand {
        padding: $spacer-3;
        border-bottom: 2px solid $su-orange-primary;
        @include breakpoint(md) {
          border: none;
        }
      }
      &__home-link {
        display: block;
        width: 250px;
      }
      &__button-container {
        padding: $spacer-3;
        &-mobile {
          padding: $spacer-3;
          display: flex;
          justify-content: flex-end;
          border-bottom: $border-light;
          & > div {
            display: flex;
          }
        }
      }
    }
</style>
