<!-- eslint-disable max-len -->
<template>
    <div class="full-page">
        <page-template>
            <div slot="page-header">
                <page-header>
                    <div slot="header-buttons">
                        <router-link :to="{name: 'Audience'}" class="button button-primary">
                            <span class='icon-left'>
                                <i class='icon-left-arrow'></i>
                            </span>Back
                        </router-link>
                    </div>
                    <div slot="mobile-buttons">
                        <router-link :to="{name: 'Audience'}" class="button button-primary">
                            <span class='icon-left'>
                                <i class='icon-left-arrow'></i>
                            </span>Back
                        </router-link>
                    </div>
                </page-header>
            </div>
            <div slot="content" class="full-page">
                <subnav>
                    <ul class="flex subnav__nav-list" slot="subnav-list">
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Home'}">Home</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Overview'}">Overview</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Audience'}">Audience</router-link>
                        </li>
                    </ul>
                </subnav>
                <landing-template>
                    <div slot="landing-subhead" class="landing-subhead">
                        Graduate
                    </div>
                    <div slot="landing-title" class="landing-title">
                        <h1 class="heading-size-h2 margin-bottom-2">2. Goal</h1>
                    </div>
                    <div slot="landing-content" class="landing-content">
                        <p>What is the overall campaign goal for the landing pages?
                        If a form is required to collect information, <strong>lead generation</strong> templates should be used.</p>
                        <p>If not, the page will be focused on <strong>general information</strong>. A content-focused  approach helps to provide awareness and direct the reader to specific informational content or a curated experience.</p>
                    </div>
                    <div slot="landing-buttons">
                        <router-link :to="{name: 'Campaign Topic Graduate Lead Generation'}" class="button button-primary margin-bottom">
                            Lead Generation
                            <span class='icon-right'>
                                <i class='icon-right-arrow'></i>
                            </span>
                        </router-link>
                        <router-link :to="{name: 'Campaign Topic Graduate General Information'}" class="button button-primary">
                            Informational
                            <span class='icon-right'>
                                <i class='icon-right-arrow'></i>
                            </span>
                        </router-link>
                    </div>
                </landing-template>
            </div>
        </page-template>
    </div>
</template>

<script>
import PageTemplate from '../components/base/page-template.vue';
import LandingTemplate from '../components/base/landing-template.vue';
import Subnav from '../components/base/subnav.vue';
import PageHeader from '../components/base/page-header.vue';

export default {
  name: 'goal',
  title: 'Decision Tree - Goal Graduate',
  components: {
    PageTemplate,
    LandingTemplate,
    PageHeader,
    Subnav,
  },
};
</script>
