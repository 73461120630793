<!-- eslint-disable max-len -->
<template>
    <div class="full-page">
        <page-template>
            <div slot="page-header">
                <page-header>
                    <div slot="header-buttons">
                        <router-link :to="{name: 'Goal Undergraduate'}" class="button button-primary">
                            <span class='icon-left'>
                                <i class='icon-left-arrow'></i>
                            </span>Back
                        </router-link>
                    </div>
                    <div slot="mobile-buttons">
                        <router-link :to="{name: 'Goal Undergraduate'}" class="button button-primary">
                            <span class='icon-left'>
                                <i class='icon-left-arrow'></i>
                            </span>Back
                        </router-link>
                    </div>
                </page-header>
            </div>
            <div slot="content" class="full-page">
                 <subnav>
                    <ul class="flex subnav__nav-list" slot="subnav-list">
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Home'}">Home</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Overview'}">Overview</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Audience'}">Audience</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Goal Undergraduate'}">Goal</router-link>
                        </li>
                    </ul>
                </subnav>
                <landing-template>
                    <div slot="landing-subhead" class="landing-subhead">
                        Undergraduate | Lead Generation
                    </div>
                    <div slot="landing-title" class="landing-title">
                        <h1 class="heading-size-h2">3. Campaign Topic</h1>
                    </div>
                    <div slot="landing-content" class="landing-content">
                        <p>Undergraduate campaigns will feature the program offerings for a school or college. It can include special courses and application information, as appropriate.</p>
                    </div>
                    <div slot="landing-buttons">
                        <router-link :to="{name: 'Content Undergrad Lead Single'}" class="button button-primary margin-bottom">
                            Single Academic Program
                            <span class='icon-right'>
                                <i class='icon-right-arrow'></i>
                            </span>
                        </router-link>
                        <router-link :to="{name: 'Content Undergrad Lead Multi'}"  class="button button-primary">
                            Multiple Academic Programs
                            <span class='icon-right'>
                                <i class='icon-right-arrow'></i>
                            </span>
                        </router-link>
                    </div>
                </landing-template>
            </div>
        </page-template>
    </div>
</template>

<script>
import PageTemplate from '../components/base/page-template.vue';
import LandingTemplate from '../components/base/landing-template.vue';
import PageHeader from '../components/base/page-header.vue';
import Subnav from '../components/base/subnav.vue';

export default {
  name: 'campaign-topic',
  title: 'Decision Tree - Campaign Topic',
  components: {
    PageTemplate,
    LandingTemplate,
    PageHeader,
    Subnav,
  },
};
</script>
