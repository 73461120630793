import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Overview from '../views/Overview.vue';
import Audience from '../views/Audience.vue';
import GoalGrad from '../views/GoalGrad.vue';
import GoalUndergrad from '../views/GoalUndergrad.vue';
import CampaignTopicGradGen from '../views/CampaignTopicGradGen.vue';
import CampaignTopicGradLead from '../views/CampaignTopicGradLead.vue';
import CampaignTopicUndergradGen from '../views/CampaignTopicUndergradGen.vue';
import CampaignTopicUndergradLead from '../views/CampaignTopicUndergradLead.vue';
import ContentUnderLeadSingle from '../views/ContentUnderLeadSingle.vue';
import ContentUnderLeadMulti from '../views/ContentUnderLeadMulti.vue';
import ContentUnderGenSingle from '../views/ContentUnderGenSingle.vue';
import ContentUnderGenMulti from '../views/ContentUnderGenMulti.vue';
import ContentGradLeadSingle from '../views/ContentGradLeadSingle.vue';
import ContentGradLeadMulti from '../views/ContentGradLeadMulti.vue';
import ContentGradGenSingle from '../views/ContentGradGenSingle.vue';
import ContentGradGenMulti from '../views/ContentGradGenMulti.vue';

import CTAUnderLeadSingle from '../views/CTAUnderLeadSingle.vue';
import CTAUnderLeadMulti from '../views/CTAUnderLeadMulti.vue';
import CTAUnderGenSingle from '../views/CTAUnderGenSingle.vue';
import CTAUnderGenMulti from '../views/CTAUnderGenMulti.vue';
import CTAGradLeadSingle from '../views/CTAGradLeadSingle.vue';
import CTAGradLeadMulti from '../views/CTAGradLeadMulti.vue';
import CTAGradGenSingle from '../views/CTAGradGenSingle.vue';
import CTAGradGenMulti from '../views/CTAGradGenMulti.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview,
  },
  {
    path: '/audience',
    name: 'Audience',
    component: Audience,
  },
  {
    path: '/goal-grad',
    name: 'Goal Graduate',
    component: GoalGrad,
  },
  {
    path: '/goal-undergraduate',
    name: 'Goal Undergraduate',
    component: GoalUndergrad,
  },
  {
    path: '/campaign-topic-graduate-general',
    name: 'Campaign Topic Graduate General Information',
    component: CampaignTopicGradGen,
  },
  {
    path: '/campaign-topic-graduate-lead',
    name: 'Campaign Topic Graduate Lead Generation',
    component: CampaignTopicGradLead,
  },
  {
    path: '/campaign-topic-undergraduate-general',
    name: 'Campaign Topic Undergraduate General Information',
    component: CampaignTopicUndergradGen,
  },
  {
    path: '/campaign-topic-undergraduate-lead',
    name: 'Campaign Topic Undergraduate Lead Generation',
    component: CampaignTopicUndergradLead,
  },
  {
    path: '/content-undergrad-lead-single',
    name: 'Content Undergrad Lead Single',
    component: ContentUnderLeadSingle,
  },
  {
    path: '/content-grad-lead-single',
    name: 'Content Grad Lead Single',
    component: ContentGradLeadSingle,
  },
  {
    path: '/content-grad-lead-multi',
    name: 'Content Grad Lead Multi',
    component: ContentGradLeadMulti,
  },
  {
    path: '/content-grad-gen-single',
    name: 'Content Grad Gen Single',
    component: ContentGradGenSingle,
  },
  {
    path: '/content-grad-gen-multi',
    name: 'Content Grad Gen Multi',
    component: ContentGradGenMulti,
  },
  {
    path: '/content-undergrad-lead-mulit',
    name: 'Content Undergrad Lead Multi',
    component: ContentUnderLeadMulti,
  },
  {
    path: '/content-undergrad-general-single',
    name: 'Content Undergrad General Single',
    component: ContentUnderGenSingle,
  },
  {
    path: '/content-undergrad-general-multi',
    name: 'Content Undergrad General Multi',
    component: ContentUnderGenMulti,
  },
  {
    path: '/cta-undergrad-lead-single',
    name: 'CTA Undergrad Lead Single',
    component: CTAUnderLeadSingle,
  },
  {
    path: '/cta-grad-lead-single',
    name: 'CTA Grad Lead Single',
    component: CTAGradLeadSingle,
  },
  {
    path: '/cta-grad-lead-multi',
    name: 'CTA Grad Lead Multi',
    component: CTAGradLeadMulti,
  },
  {
    path: '/cta-grad-gen-single',
    name: 'CTA Grad Gen Single',
    component: CTAGradGenSingle,
  },
  {
    path: '/cta-grad-gen-multi',
    name: 'CTA Grad Gen Multi',
    component: CTAGradGenMulti,
  },
  {
    path: '/cta-undergrad-lead-mulit',
    name: 'CTA Undergrad Lead Multi',
    component: CTAUnderLeadMulti,
  },
  {
    path: '/cta-undergrad-general-single',
    name: 'CTA Undergrad General Single',
    component: CTAUnderGenSingle,
  },
  {
    path: '/cta-undergrad-general-multi',
    name: 'CTA Undergrad General Multi',
    component: CTAUnderGenMulti,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  // eslint-disable-next-line consistent-return
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
          });
        }, 100);
      });
    } if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router;
