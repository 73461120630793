<!-- eslint-disable max-len -->
<template>
    <div class="overview">
        <page-template>
          <div slot="page-header">
            <page-header>
              <div slot="header-buttons" class="header-buttons">
                <router-link :to="{name: 'Audience'}" class="button button-primary">
                  Next
                  <span class='icon-right'>
                    <i class='icon-right-arrow'></i>
                  </span>
                </router-link>
              </div>
              <div slot="mobile-buttons">
                <router-link :to="{name: 'Audience'}" class="button button-primary">
                  Next
                  <span class='icon-right'>
                    <i class='icon-right-arrow'></i>
                  </span>
                </router-link>
              </div>
            </page-header>
          </div>
          <div slot="subnav">
            <subnav>
              <ul class="flex subnav__nav-list" slot="subnav-list">
                  <li class="flex subnav__nav-item">
                    <router-link :to="{name: 'Home'}">Home</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                  </li>
                  <li class="flex subnav__nav-item">
                    <router-link :to="{name: 'Overview'}">Overview</router-link>
                  </li>
              </ul>
            </subnav>
          </div>
            <div slot="content" class="overview__content">
              <div class="container-xl">
                <div class="overview__heading">
                  <h1>Overview</h1>
                  <h2>Best Practices &amp; Takeaways</h2>
                </div>
                <div class="overview__body">
                  <ol class="overview__body-list">
                    <li>Hero image and headline</li>
                    <li>Call  To Action (CTA)
                      <ul>
                        <li>Subhead leading to CTA</li>
                        <li>Form with CTA (lead generation) or a CTA to direct user to a form page (general information).</li>
                      </ul>
                    </li>
                    <li>Why this school/college</li>
                    <li>Features/Benefits of the school/college or program to the prospect
                      <ul>
                        <li>Use of bullets to break up content</li>
                      </ul>
                    </li>
                    <li>Who is this program/degree for and how can it benefit him or her in the future</li>
                    <li>Other related programs and/or info about school/college
                      <ul>
                        <li>Multiple program/degree offering with a drop-down menu for the user to select from</li>
                        <li>Sometimes these drop-down menus contain their own CTA, bringing user back to the form</li>
                      </ul>
                    </li>
                    <li>Student or academic testimonials</li>
                    <li>Accreditations &amp; accolades</li>
                    <li>Repeat CTA</li>
                  </ol>
                </div>
              </div>
            </div>
        </page-template>
    </div>
</template>

<script>
import PageHeader from '../components/base/page-header.vue';
import PageTemplate from '../components/base/page-template.vue';
import Subnav from '../components/base/subnav.vue';

export default {
  name: 'Overview',
  title: 'Decision Tree Overview',
  components: {
    PageTemplate,
    Subnav,
    PageHeader,
  },
};
</script>

<style lang="scss" scoped>
  .overview {
    height: 100%;
    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $spacer-3;
      margin-top: 220px;
      @include breakpoint(lg) {
        margin-top: 50px;
      }
    }
    &__body {
      &-list {
        @include breakpoint(lg) {
          column-count: 2;
          column-gap: $spacer-6;
        }
      }
    }
  }
</style>
