<!-- eslint-disable max-len -->
<template>
  <div class="home">
    <page-template>
      <div slot="page-header">
        <page-header>
          <div slot="header-buttons">
            <router-link :to="{name: 'Overview'}" class="button button-primary">
              Next
              <span class='icon-right'>
                <i class='icon-right-arrow'></i>
              </span>
            </router-link>
          </div>
          <div slot="mobile-buttons">
            <router-link :to="{name: 'Overview'}" class="button button-primary">
              Next
              <span class='icon-right'>
                <i class='icon-right-arrow'></i>
              </span>
            </router-link>
          </div>
        </page-header>
      </div>
      <mobile-buttons></mobile-buttons>
      <div slot="content" class="home__body">
        <div class="home__background">
          <laurel-supergraphic class="home__background-image"/>
          <div class="home__content">
            <div class="container-md home__content-text">
              <div class="padding-3">
                <h1 class="text-white">Campaign Landing Page Guide:</h1>
                <h2 class="text-white">Decision Tree</h2>
                <p class="text-white">The decision tree is a process tool that provides Syracuse University's schools and colleges with guidance on how to approach the creation of a successful landing page experience.</p>
                  <div class="flex flex-justify-start margin-top-6">
                    <router-link :to="{name: 'Overview'}" class="button button-inverse-white">
                      Get Started
                      <span class='icon-right'>
                        <i class='icon-right-arrow'></i>
                      </span>
                    </router-link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </page-template>
  </div>
</template>

<script>
import PageHeader from '../components/base/page-header.vue';
import PageTemplate from '../components/base/page-template.vue';
import LaurelSupergraphic from '../components/images/laurel-supergraphic.vue';

export default {
  name: 'Home',
  title: 'Decision Tree - Home',
  components: {
    PageTemplate,
    LaurelSupergraphic,
    PageHeader,
  },
};
</script>

<style lang="scss" scoped>
  .home {
    width: 100%;
    height: 100%;
    &__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      z-index: 98;
      margin-top: 80px;
      &-text {
        margin-top: -80px;
      }
    }
    &__body {
      width: 100%;
      height: 100%;
      margin-top: 80px;
      @include breakpoint(lg) {
        margin-top: 0;
      }
    }
    &__background {
      background-color: $su-orange-dark;
      width: 100%;
      height: 100%;
      color:$white;
      &-image {
        height: 100%;
        position: absolute;
        right: 0;
        top: 40px;
        opacity: .3;
      }
    }
    &__table-contents {
      &-list {
        padding: 0;
        list-style-position: inside;
        &-item {
          padding: 0 $spacer-3;
        }
      }
      &-link {
        color: $white;
        text-decoration: underline;
        &:hover {
          color: $su-orange-dark-40;
          text-decoration: underline;
        }
      }
    }
  }
</style>
