<!-- eslint-disable max-len -->
<template>
    <div class="content">
        <page-template>
            <div slot="page-header">
                <page-header>
                    <div slot="header-buttons" class="header-buttons">
                        <router-link :to="{name: 'Campaign Topic Undergraduate General Information'}" class="button button-primary margin-right">
                            <span class='icon-left'>
                                <i class='icon-left-arrow'></i>
                            </span>Back
                        </router-link>
                        <router-link :to="{name: 'CTA Undergrad General Multi'}" class="button button-primary">
                            Next
                            <span class='icon-right'>
                                <i class='icon-right-arrow'></i>
                            </span>
                        </router-link>
                    </div>
                    <div slot="mobile-buttons">
                        <router-link :to="{name: 'Campaign Topic Undergraduate General Information'}" class="button button-primary margin-right">
                            <span class='icon-left'>
                                <i class='icon-left-arrow'></i>
                            </span>Back
                        </router-link>
                        <router-link :to="{name: 'CTA Undergrad General Multi'}" class="button button-primary">
                            Next
                            <span class='icon-right'>
                                <i class='icon-right-arrow'></i>
                            </span>
                        </router-link>
                    </div>
                </page-header>
            </div>
            <div slot="content" class="">
                <subnav>
                    <ul class="flex subnav__nav-list" slot="subnav-list">
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Home'}">Home</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Overview'}">Overview</router-link><i class="icon-chevron-right margin-x-3 flex flex-items-center"></i>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Audience'}">Audience</router-link><span class="icon-chevron-right margin-x-3 flex flex-items-center"></span>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Goal Undergraduate'}">Goal</router-link><span class="icon-chevron-right margin-x-3 flex flex-items-center"></span>
                        </li>
                        <li class="flex subnav__nav-item">
                            <router-link :to="{name: 'Campaign Topic Undergraduate General Information'}">Campaign Topic</router-link>
                        </li>
                    </ul>
                </subnav>
                <div class="content__body">
                    <div class="content__section">
                        <div class="content__navigation">
                            <div class="content__navigation-fixed">
                                <h3 class="heading-size-h4 is-bold">Content Sections</h3>
                                <ol>
                                    <li>
                                        <a href="#" v-scroll-to="'#title'">Title</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#program-image'">Program Image</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#school-logo'">School Logo</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#cta'">CTA</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#about-program-school'">About Program &amp; School</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#other-programs'">Other Programs</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#value-prop'">Value Proposition</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#proof-points'">Proof Points</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#cta-repeat'">CTA Repeat</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#campus-image'">Campus Image</a>
                                    </li>
                                    <li>
                                        <a href="#" v-scroll-to="'#footer'">Footer</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="content__section-content">
                            <div class="content__header">
                                <div class="content__header-content">
                                    <p class="is-bold">Undergraduate | Informational | Multi-Programs</p>
                                    <h1>4. Content</h1>
                                    <p class="border-left-6 border-orange padding-left-6">Content supports the goal of the site and helps to engage with CTA.
                                        Content can help overcome pain points, show value or highlight proof points. Opportunity to A/B test and optimize page flow.
                                    </p>
                                </div>
                            </div>
                            <div class="content__section-columns">
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/ContentTitle.png" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="title" tabindex="-1">01. Title</h3>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/ContentHeroImage.png" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="program-image" tabindex="-1">02. Program Image</h3>
                                    <p>Should be a match to the media (Banner Ads)</p>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/ContentLogo.jpg" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="school-logo" tabindex="-1">03. School Logo</h3>
                                    <p>Should be a match to the media (Banner Ads), and can be linked to the school/college homepage.</p>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/ContentCTA2.jpg" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="cta" tabindex="-1">04. CTA</h3>
                                    <p>The title to the CTA should appear above the fold (see step 5).</p>
                                    <p><a href="https://documentation.unbounce.com/hc/en-us/articles/203509974-Integrating-with-Google-Analytics">
                                        Click tracking</a> should be implemented on all CTA's and outbound links.</p>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/LeadSingleBanner.png" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="about-program-school" tabindex="-1">05. About Program &amp; School</h3>
                                    <p>Speaking directly to the prospect about:</p>
                                    <ul>
                                        <li>Why this program</li>
                                        <li>Who this program is intended for</li>
                                        <li>How long this program has been around</li>
                                        <li>Features/Benefits of program - content broken up by bullets</li>
                                    </ul>
                                    <p>-or-</p>
                                    <p>Speaking directly to the prospect about:</p>
                                    <ul>
                                        <li>Why this school/college</li>
                                        <li>Who this school/college is for</li>
                                        <li>How long this school/college has been around</li>
                                        <li>Features/Benefits of school/college - content broken up by bullets</li>
                                    </ul>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/LeadSingleAccordion.png" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="other-programs" tabindex="-1">06. Other or Multiple Programs</h3>
                                    <ul>
                                        <li>Related or multiple programs/offerings</li>
                                        <li>Include CTA to bring user to web page to learn more or jump to form</li>
                                    </ul>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/LeadSingleBenefits.png" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="value-prop" tabindex="-1">07. Value Proposition</h3>
                                    <p>Speaking directly to the prospect about</p>
                                    <ul>
                                        <li>What this school does for its students</li>
                                        <li>How this school resolves pain points</li>
                                        <li>How going here benefits their future</li>
                                        <li> Accolades and accreditations
                                            <br>- school rank, funding, number of classes
                                            <br>- number of job prospects after graduation</li>
                                    </ul>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/LeadSingleStudent.png" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="proof-points" tabindex="-1">08. Proof Points</h3>
                                    <ul>
                                        <li>Student/Academic testimonial</li>
                                        <li>Professional testimonial</li>
                                    </ul>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/LeadSingleCTA2.png" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="cta-repeat" tabindex="-1">09. CTA Repeat</h3>
                                    <p>Give the user a path back to the call to action</p>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/LeadSingleCampusImage.jpg" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="campus-image" tabindex="-1">10. Campus Image</h3>
                                    <p>Should be a match to the media (Banner Ads)</p>
                                </div>
                                <div class="content__section-image-block">
                                    <div class="content__section-image">
                                        <img src="../assets/images/LeadSingleFooter.jpg" alt="logo-section" />
                                    </div>
                                </div>
                                <div class="content__section-text-block">
                                    <h3 class="heading-size-h4 is-bold anchor-link" id="footer" tabindex="-1">11. Footer</h3>
                                    <p>Should be a match to the media (Banner Ads)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </page-template>
    </div>
</template>

<script>
import PageTemplate from '../components/base/page-template.vue';
import Subnav from '../components/base/subnav.vue';
import PageHeader from '../components/base/page-header.vue';

export default {
  name: 'Content',
  title: 'Decision Tree - Content',
  components: {
    PageTemplate,
    PageHeader,
    Subnav,
  },
};
</script>

<style lang="scss" scoped>
</style>
